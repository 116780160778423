<template>
	<div
		class="header-menu"
		:class="{visible: getIsVisible}"
	>

		<div class="header-menu__button">
			<icon
				size="sm"
				colour="blue"
				icon="cross"
				@click.native="toggleDrawer"
			/>
		</div>

		<div
			class="header-menu__items"
		>
			<drawer-item
				v-for="(menuItem, i) in getMenuItems"
				:key="i"
				v-bind="menuItem"
			/>
		</div>
	</div>
</template>

<script>
	import Icon from '@/components/ui/Icon';
	import DrawerItem  from '@/components/ui/header/headerSmall/DrawerItem';

	export default {
		components: {
			DrawerItem,
			Icon
		},
		computed: {
			getIsVisible () {
				return this.$store.getters['ui/getDrawerIsVisible'];
			},
			getMenuItems () {
				return [
					{
						text: 'Find a course',
						route: '/browse-courses'
					},
					(this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'My learning',
							route: '/my-courses'
						} : false
					),
					{
						text: 'About',
						route: '/about'
					},
					{
						text: 'Glossary',
						route: '/glossary'
					},
					(this.$store.getters['auth/getIsTeacher'] ?
						{
							text: 'Teaching',
							route: '/teaching'
						} : false
					),
					(this.$store.getters['auth/getIsAdmin'] ?
						{
							text: 'Admin',
							route: '/admin'
						} : false
					),
					(!this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'Sign in',
							route: '/login'
						} : false
					),
					(this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'My profile',
							route: '/my-profile'
						} : false
					),
					(this.$store.getters['auth/getIsAuthenticated'] ?
						{
							text: 'Log out',
							action: 'auth/logOut'
						} : false
					)
				].filter(Boolean);
			}
		},
		mounted () {
			window.addEventListener('resize', this.onResize);
		},
		beforeDestroy () {
			window.removeEventListener('resize', this.onResize);
		},
		methods: {
			onResize () {
				const mq = window.matchMedia('(min-width:899px)');
				if (!mq?.matches) {
					return;
				}
				if (!this.getIsVisible) {
					return;
				}
				this.$store.commit('ui/setDrawerIsHidden');
			},
			toggleDrawer () {
				this.$store.commit('ui/toggleDrawerIsVisible');
			}
		}
	};

</script>

<style lang="scss" scoped>
  .header-menu {
    position: fixed;
		z-index: 4;
		overflow-y: auto;
		top: 0;
		left: 100vw;
		width: 320px;
		height: 100vh;
		background-color: $c-white;
		transition:transform 0.6s ease;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.72);

    &.visible {
      transform: translate3d(-100%,0,0);
    }

    &__button {
      position: absolute;
			top: rem(8);
			right: rem(8);

      user-select: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: background 0.2s ease;
      border-radius: 8px;
      padding:rem(4);

      &:hover {
        background-color:$c-brand-grey-lighter-7;
      }

      [data-component='icon'] {
        width: rem(32);
        height: auto;
        @media (min-width:965px) {
          width: rem(38);
        }
        @media (min-width:1080px) {
          width: rem(42);
        }
      }
    }

    &__items {
			display:flex;
			flex-direction:column;
			margin: rem(32) rem(48);
		}
  }
</style>
