<template>
	<router-link class="glossary-button" to="/glossary" title="Glossary">
		<icon icon="v2-book-round" colour="transparent" />
	</router-link>
</template>

<script>

	import Icon from '@/components/ui/Icon.vue';

	export default {
		components: {
			Icon
		},
		props: {},
		data: () => {
			return {};
		},
		computed: {},
		methods: {}
	};

</script>

<style lang="scss" scoped>

	.glossary-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		border-radius: 50%;

		svg {
			width: 36px;
			height: 36px;
		}

		@media (max-width: 1279px) {

		}
	}

</style>
