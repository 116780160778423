import { render, staticRenderFns } from "./V2AppHeaderMenu.vue?vue&type=template&id=35d6d800&scoped=true&"
import script from "./V2AppHeaderMenu.vue?vue&type=script&lang=js&"
export * from "./V2AppHeaderMenu.vue?vue&type=script&lang=js&"
import style0 from "./V2AppHeaderMenu.vue?vue&type=style&index=0&id=35d6d800&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d6d800",
  null
  
)

export default component.exports